@import '../../styles/variables.scss';

.discover-heading{

    @media only screen and(max-width:900px){
        padding-top: 5rem;
    }
    width: 80vw;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 11rem;
    margin-bottom: 10rem;

    &__title{
        color: white;
        font-weight: 500;
        border-bottom: 1px solid white;
        display: inline-block;
        font-size: 3.5rem;
        padding: 0 2rem;
    }
    &__form{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3rem;

        &__button{
            padding: 1rem 3rem;
            border-radius: 5px;
            box-shadow: $--shadow-box;
            cursor: pointer;
            margin-top: 3rem;
            font-size: 2rem;
            font-weight: 600;
            letter-spacing: 2.2px;
            border: 2px solid $--color-secondary;
            color: $--color-secondary;
            background-color: inherit;
           }

        &__container{
            text-align: center;
            &__select{
                margin: 1rem;
                padding: 1rem;
                border: 2px solid $--color-secondary;
                border-radius: 5px;

                &__option{
                background-color: white;
                }
            }
            &__input{
            margin: 1rem;
            padding: 1rem;
            border: 2.5px solid $--color-secondary;
            border-radius: 5px;
            }
        }
    }
}

.pagination-buttons{

    @media only screen and(max-width:768px){
        width: 80vw;
    }
    width: 20vw;
    text-align: center;
    margin: 0 auto;
    margin-top: 7rem;
     &__btn,
     &__btn:visited,
     &__btn:active  {
        
         text-transform: uppercase;
         font-size: 1.5rem;
         width: 50%;
         height: 4rem;
         border: 2px solid $--color-secondary;
         color: $--color-secondary;
         background-color: inherit;
         font-weight: 600;
         letter-spacing: 2px;
         cursor: pointer;
         border-radius: 10px;
     }
    }