@import '../../styles/variables.scss';

.people-slider{

margin-top: 10rem;
&__title{
    text-transform: uppercase;
    letter-spacing: 3.5px;
    padding-bottom: 3rem;
}

 &__card{
        transition: all .2s;
        padding: 3rem;
        opacity: .7;
        position: relative;
        @include slider-flex;

        &__img{
            display: block;
            margin: 0 auto;
            max-height: 16rem;
            border-radius: 5%;
         }

        &__name{
            text-align: center;
        }

        &:hover{
            transform: translateY(-1rem);
            opacity: 1;
        }
    }
}