@import './styles/variables.scss';

body {
    font-family: sans-serif;
    line-height: 1.5;

    background-color: $--color-primary;
    background-size: cover;
  } 

html{
  @media only screen and(max-width:768px){
    font-size: 45%;
  }
  font-size: 62.5%;
  font-family: sans-serif
}

*{
  margin: 0;
  padding: 0;
}
