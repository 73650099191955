@import '../../styles/variables.scss';


.buttons-container{
    @media only screen and(max-width:600px){
        width: 100vw;
    }
    display:flex;
    width: 80vw;
    margin: 0 auto;
    margin-bottom: 7rem;
    justify-content: space-around;
}

.home_buttons{
    @media only screen and(max-width:600px){
        padding: .1rem;
        
    }
    border-radius: 10px;
    padding: .2rem;
    text-transform: uppercase;
    width: 48%;
    height: 5rem;
    background-image: linear-gradient(90deg, rgba(255,86,132,.4) 0%, rgba(83,50,201,.4) 68%),
    linear-gradient(90deg, rgba(255,86,132,.4) 0%, rgba(83,50,201,.4) 68%);
}

.home__btn{
    cursor: pointer;
    flex:1;
    width: 100%;
    padding: 1rem 1rem;
    background-color: $--color-primary;
    border:none;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 7px;
    font-size: 1.5rem;
    font-weight: 400;
    color:white;
    line-height: 1.7;
    
    @media only screen and(max-width:400px){
        font-size: 1.25rem;
    }
}   




