.trailer-slider{
    margin-top: 7rem;
    
    &__heading{
        text-transform: uppercase;
        letter-spacing: 3px;
        padding-bottom: 2.5rem;
    }
}

iframe{
    @media only screen and(max-width:768px){
        height: 10rem;
        width: 15rem;
    }
    margin: 0 auto;
    border: none;
    height: 15rem;
    display: block;
}