@import '../../styles/variables.scss';
.home--slider{
    margin-bottom: -2rem;
    background-color: black;
    display: block;
&__img{
    display: block;
    width: 100%;
    opacity: .4;
    max-height: 100vh;
}
&__card{
    position: relative;
    width: auto;
}
}

.movie-info{
position: absolute;
bottom: 10%;
left: 5%;
    &__NP{
    color:#cccccc;
    font-size: 2.2rem;
    }
    &__title{
    color:white;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2;
    }
    &__genre{
    color:#cccccc;
    font-size: 2.2rem;
    font-weight: 200;
    }
}