@import './variables.scss';

.slider-title{
    text-align: center;
    margin: 0 auto;
}

.heading-3{
    font-size: 1.8rem;
    color: $color-grey-light2;
    font-weight: 400;
    line-height: 1.6;
}

.heading-2{
    font-size: 2.5rem;
    font-weight: 200;
    color: $color-grey-light1;
    line-height: 1.5;
}