@import '../../styles/variables.scss';

.discover-main{
    display: grid;
    width: 80vw;
    margin: 0 auto;
    justify-items: center;
    align-items: center;
    row-gap: 4rem;
    grid-template-columns: repeat(auto-fit,minmax(24rem,1fr));

   &__movie-card{
       opacity: .8;
       width: 155px;
       display: flex;
       height: 35rem;
       flex-direction: column;
       justify-content: flex-start;
       align-items: center;
       transition: all .25s;
       position: relative;

       &__img{
           border-radius: 7px;
           box-shadow: $--shadow-box;
           width: 13rem;
           display: block;
           
       }
       &__title{
           text-align: center;
           font-size: 1.4rem;
           width: 11rem;
       }
       &__rating{
           display: flex;
           font-size: 1.5rem;
        &__icon{
            fill:$--color-secondary;
            width: 2rem;
            height: 2rem;
            margin-bottom: .2rem;
        }
       }
       &:hover{
           transform: scale(1.1);
           opacity: 1;
       }
   }
}

.discover{
    position: relative;
}

.pagination-buttons{
width: 20vw;
text-align: center;
margin: 0 auto;
margin-top: 7rem;
 &__btn,
 &__btn:visited,
 &__btn:active  {
    
     text-transform: uppercase;
     font-size: 1.5rem;
     width: 50%;
     height: 4rem;
     border: 2px solid $--color-secondary;
     color: $--color-secondary;
     background-color: inherit;
     font-weight: 600;
     letter-spacing: 2px;
     cursor: pointer;
     border-radius: 10px;
 }
}