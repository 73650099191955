@import '../../styles/variables.scss';

.star-rating-container {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(6, 15px);
  grid-template-rows: 15px;
  position: relative;
  width: 15px * 6;
  

  &__item {
    cursor: pointer;
    fill: white;
    height: 15px;
    width: 15px;

    &--active {
      fill: $--color-secondary;
    }
  }

  &-warning {
    background-color: rgba(255, 0 ,0, .7);
    color: $color-grey-light1;
    font-weight: bold;
    left: -2rem;
    padding: .5rem;
    position: absolute;
    top: 1.5rem;
    width: 200px;

    &--hide {
      display: none;
    }
  }
}